/**
 * @author Sky Lin
 * @date 2019/12/13
 */
import React, { useState, useEffect } from 'react';
import { loadConfig } from '../service/api';
import styles from '../assets/css/lifeHouse.module.scss';
import Layout from '../components/layout/index';

const getConf = async () => {
  try {
    const conf = await loadConfig();
    const { lifeHousePage } = conf.data || {};
    return lifeHousePage.goods || [];
  } catch (e) {
    console.error(e);
  }
};

export default () => {
  const {
    appContainer,
    banner,
    goodsWrapper,
    goodItem,
    goodContent,
    tip,
  } = styles;
  const [goods, setGoods] = useState([]);

  useEffect(() => {
    (async () => {
      const goodsList = await getConf();
      setGoods(goodsList);
    })();
  }, []);
  return (
    <Layout>
      <div className={banner}></div>
      <div className={goodsWrapper}>
        {goods.map((item, index) => (
          <div className={goodItem} key={index}>
            <div className={goodContent}>
              <h1>{item.title}</h1>
              {index < 2 && <p>{item.summary}</p>}
              <a href={item.link} target="_blank">
                立即预订
              </a>
            </div>
            <img src={item.img} alt="goods" />
          </div>
        ))}
        <div className={tip}>关注“路客美宿”公众号，发现更多品质好物</div>
      </div>
    </Layout>
  );
};
